<template>
        <Drawer v-model:visible="theModel" position="right" class="slidePanel editPanelActor" :dismissable="false">
                <template #header>
                        <div>{{ header }}</div>
                </template>

                <div class="editingbody">
                    <UInputField type="input" v-model="actor.name" name="Название компании" :asterics=true :limit=100></UInputField>
                    <UInputField type="input" v-model="actor.inn" name="ИНН" :asterics=true class="mt-3" :limit=100></UInputField>
                    <UInputField v-model="actor.comment" name="Комментарий" class="mt-3"></UInputField>
                </div>

                <div class="downButtonPanel">
                        <Button @click="save" v-if="!isCreate" :disabled="disableSave">Сохранить</Button>
                        <Button @click="save" v-if="isCreate" :disabled="disableSave">Создать</Button>
                </div>
        </Drawer>
</template>

<script setup lang="ts">
        import {computed} from "vue";
        import {CActor} from "@/interfaces";
        import controllerEditor from "@/editors/controller/controllerEditor";


        const props = defineProps<{ modelValue: boolean, editingData: CActor }>()
        const emit = defineEmits(['update:modelValue', "save"])

        const actor = computed(() => props.editingData)

        const {
                theModel,
                header,
                save,
                editingData,
                disableSave,
                isCreate
        } = controllerEditor({
                props, emit,
                headerCreate: "Создание актора",
                headerEdit: "Редактирование актора " + actor.value.id,
        })

</script>
