<template>
        <div class='flex h-full flex-column' v-if='loaded'>
                <HeadPanel v-if='authenticated'></HeadPanel>
                <div class='flex h-full overflow-hidden pb-2'>
                        <div class='menu' v-if='authenticated'>
                                <div v-for='(item, index) in items' :key='item.label' class='itemmenu'>
                                        <router-link v-slot='{ navigate, isActive }' :to='item.to' custom
                                                     v-if='item.to'>
                                                <div class='elmenu elmenu_target'
                                                     @click='onHeaderActionClick($event, navigate)'
                                                     :class='{active : isActive}'>
                                                        <div v-if='item.icon'
                                                             :class="['menuitem-icon', item.icon]"></div>
                                                        <div class='menuitem-text'>{{ item.label }}</div>
                                                </div>
                                        </router-link>
                                </div>
                        </div>
                        <router-view></router-view>
                </div>
        </div>

        <Dialog :header='dlgData.header' v-model:visible='dlgData.visible' :modal='true' :closable='dlgData.closable' v-if='dlgData.visible'>
                <template v-if='dlgData.icon'>
                        <div class='flex'>
                                <div class='dlg_icon' v-html='dlgData.icon'></div>
                                <div v-html='dlgData.message'></div>
                        </div>
                </template>
                <div v-else v-html='dlgData.message'></div>
                <template #footer>
                        <Button v-for='btn in dlgData.buttons' :key='btn.name' :label='btn.name' @click='btn.func' />
                </template>
        </Dialog>

        <Dialog header='Ошибка' v-model:visible='dlgError.visible' :modal='true' :closable='true'>
                <div class='flex'>
                        <div class='dlg_icon mr-2'><i class='pi pi-exclamation-triangle'></i></div>
                        <div v-html='dlgError.message'></div>
                </div>
        </Dialog>
</template>

<script setup lang='ts'>
        import { ref, onMounted } from 'vue';
        import HeadPanel from './panels/HeadPanel.vue';
        import common, { CDlgData } from '@/ts/common';
        import { api } from '@/ts/api';
        import { authApi } from '@/ts/api/auth.api';
        import { router } from '@/ts/router';
        import { BillingStore } from '@/ts/store';

        const authenticated = ref(authApi.isAuthenticated())
        const loaded = ref(false)

        const items = ref([
                {
                        label: 'Акторы',
                        icon: 'pi pi-user',
                        to: 'actors'
                },
                {
                        label: 'Контрагенты',
                        icon: 'pi pi-crown',
                        to: 'clients'
                },
                {
                        label: 'Контракты',
                        icon: 'pi pi-book',
                        to: 'contracts'
                },
                {
                        label: 'Программные продукты',
                        icon: 'pi pi-microsoft',
                        to: 'products'
                },
                {
                        label: 'Программные клиенты',
                        icon: 'pi pi-mobile',
                        to: 'progclients'
                },
                {
                        label: 'Лицензии',
                        icon: 'pi pi-file',
                        to: 'licenses'
                }
        ])

        const dlgData = ref(new CDlgData)
        const dlgError = ref(new CDlgData)
        common.init(dlgData.value, dlgError.value)

        const onHeaderActionClick = (event: PointerEvent, navigate: any) => (navigate && navigate(event))

        onMounted(async () => {
                if(!authenticated.value) {
                        BillingStore().currentUrl = window.location.href
                        await router.push('/login');
                }
                else {
                        await api.login(authApi.get_token());
                        await api.loadConstants();
                }
                loaded.value = true;
        })

</script>
