<template>
        <Drawer v-model:visible='theModel' position='right' class='slidePanel editPanelLicense' :dismissable='false'>
                <template #header>
                        <div>{{ header }}</div>
                </template>

                <div class='editingbody'>
                        <Tabs value='0'>
                                <TabList>
                                        <Tab value='0'>Общие</Tab>
                                        <Tab value='1'>Параметр JSON</Tab>
                                        <Tab value='2'>Лицензионные ключи</Tab>
                                </TabList>
                                <TabPanels>
                                        <TabPanel value='0'>
                                                <UInputField type='input' v-model='license.name' name='Нименование лиценнзии' class='mt-3' :asterics=true :limit=100></UInputField>

                                                <UDropdownBox name='Контракт' :options='contracts' v-model='license.contract_id' class='mt-3' :asterics='true'></UDropdownBox>

                                                <span class='mt-3 mr-2'>Приоритет в договоре (да/нет)</span>
                                                <Checkbox v-model='license.priority' :binary='true' class='mt-3' />
                                                <UDropdownBox name='Статус лицензии' :options='statuses_license' v-model='license.status' class='mt-3' :asterics='true'></UDropdownBox>

                                                <span class='mt-3 mr-2'>Дата начала</span>
                                                <DatePicker v-model='active_from' class='mt-3 mr-4' dateFormat='dd.mm.yy' />
                                                <span class='mt-3 mr-2'>Дата окончания</span>
                                                <DatePicker v-model='active_to' class='mt-3' dateFormat='dd.mm.yy' />

                                                <UInputField type='input' v-model='license.access_quantity' name='Количество доступов всего' class='line mt-3' :asterics=true :limit=10 :styleInput="{width: '150px'}"></UInputField>
                                                <UInputField type='input' v-model='license.access_granted' name='Количество выданных доступов' class='line mt-3' :asterics=true :limit=10 :styleInput="{width: '150px'}"></UInputField>

                                                <span class='mt-3 mr-2'>Действия доступа до даты</span>
                                                <DatePicker v-model='access_expiration_at' class='mt-3' dateFormat='dd.mm.yy' />
                                                <UDropdownBox name='Период лицензии' :options='period_license' v-model='license.access_period' class='mt-3' :asterics='true'></UDropdownBox>
                                                <UDropdownBox name='Программный продукт' :options='products' v-model='license.product_id' class='mt-3' :asterics='true'></UDropdownBox>

                                                <UInputField v-model='license.comment' name='Комментарий' class='mt-3' :rows='5'></UInputField>
                                        </TabPanel>

                                        <TabPanel value='1'>
                                                <UInputField v-model='license.scope_json' name='Блок данных' class='mt-3'  :rows='25'></UInputField>
                                        </TabPanel>

                                        <TabPanel value='2'>
                                                <p class='m-0 mt-3'>
                                                        Здесь будет генерация и отображение лицензионных ключей!
                                                </p>
                                        </TabPanel>
                                </TabPanels>
                        </Tabs>


                        <!--UInputField type="input" v-model="progclient.secret" name="Секретный код" :asterics=true :limit=100></UInputField-->
                </div>

                <div class='downButtonPanel'>
                        <Button @click='save' v-if='!isCreate' :disabled='disableSave'>Сохранить</Button>
                        <Button @click='save' v-if='isCreate' :disabled='disableSave'>Создать</Button>
                </div>
        </Drawer>
</template>

<script setup lang='ts'>
import { ref, computed, watch } from 'vue';
import { CAdditionDataLicense, CLicense } from '@/interfaces';
import controllerEditor from '@/editors/controller/controllerEditor';
import { BillingStore } from '@/ts/store';
import common from '@/ts/common';


const props = defineProps<{ modelValue: boolean, editingData: CLicense, additionalEditorData: CAdditionDataLicense }>();
const emit = defineEmits(['update:modelValue', 'save']);
let cStore = BillingStore();

const products = computed(() => cStore.products);
const statuses_license = computed(() => cStore.statuses_license);
const period_license = computed(() => cStore.period_license);

const license = computed(() => props.editingData);
const contracts = computed(() => props.additionalEditorData.contracts);

const active_from = ref(common.dateDate(license.value.active_from));
const active_to = ref(common.dateDate(license.value.active_to));
const access_expiration_at = ref(common.dateDate(license.value.access_expiration_at));

watch(active_from, () => license.value.active_from = common.dateFormatView(active_from.value));
watch(active_to, () => license.value.active_to = common.dateFormatView(active_to.value));
watch(access_expiration_at, () => license.value.access_expiration_at = common.dateFormatView(access_expiration_at.value));


const {
        theModel,
        header,
        save,
        editingData,
        disableSave,
        isCreate
} = controllerEditor({
        props, emit,
        headerCreate: 'Создание лицензии',
        headerEdit: 'Редактирование лицензии ' + license.value.id
});

</script>
