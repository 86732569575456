export class CEditing {
        id: number | null

        constructor(data: CEditing | null = null) {
                if(data == null) {
                        this.id = null
                } else {
                        this.id = data.id
                }
        }

        isDisableSave(): boolean {
                return false
        }

        fillForSave(dataTo: CEditing) {/**/
        }
}

export class CEditTemplate {
        constructor(data: CEditTemplate | null = null) {}
}

