<template>
    <div class="filterPanel mt-2">
        <div class="flex">
            <UInputField name="Поиск по названию" type="input" class="lineInput w-5" v-model="curFilter.name"></UInputField>
            <UInputField name="По ID" type="input" class="lineInput w-2 ml-4" v-model="curFilter.id"></UInputField>
            <UInputField name="По ИНН" type="input" class="lineInput w-2 ml-4" v-model="curFilter.inn"></UInputField>
            <div class="buttonPanel flex-shrink-0 ml-4 mt-3 w-3" style="width: 340px">
                <Button class="p-button-text" @click="clearFilter">Сбросить</Button>
                <Button class="ml-4" @click="applyFilter">Применить</Button>
                <Button class="ml-4 p-button-outlined" @click="hide">Скрыть</Button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {CFilterActor} from "@/interfaces";
    import controllerFilter from "@/panels/controller/controllerFilter";

    const emit = defineEmits(["hide", "apply", "clear"])

    const {
        curFilter,
        hide, applyFilter, clearFilter
    } = controllerFilter({
        emit,
        classFilter : CFilterActor,
    })

</script>
