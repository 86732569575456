<template>
        <div class='headPanel'>
                <div class='title'>АСТ БИЛЛИНГ — Панель администрирования</div>
                <div class='w-full'></div>
                <div class='regPanel' title='Выход'>
                        <span class='user-name'>{{ username }}</span>
                        <i class='pi pi-sign-out' @click='exit'></i>
                </div>
        </div>
</template>

<script setup lang='ts'>
        import { ref } from 'vue'
        import { BillingStore } from '@/ts/store';
        import { authApi } from '@/ts/api/auth.api';

        const username = ref(BillingStore().profile.name)

        const exit = async () => {
                await authApi.logout()
                location.reload()
        }

</script>
