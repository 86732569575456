<template>
        <div class="UDropdownBox">
                <span class='label'><label v-if="name">{{ name }}</label><span class="asterics" v-if="asterics">* </span></span>
                <Select v-model="theModel" :options="options" :optionLabel="optionLabel" :optionValue="optionValue" @change="change" :style="styleDropDown" :showClear='showClear'/>
        </div>
</template>

<script setup lang="ts">
        import {DropdownChangeEvent} from "primevue/dropdown";
        import {useVModel} from '@vueuse/core'

        const props = withDefaults(
                defineProps<{
                        name?: string | null
                        modelValue?: string | number
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        options: any[]
                        optionLabel?: string
                        optionValue?: string
                        styleDropDown?: any
                        asterics?: boolean
                        showClear?: boolean
                }>(),
                {
                        name: null,
                        modelValue: '',
                        optionLabel: 'name',
                        optionValue: 'id',
                        styleDropDown : null,
                        asterics : false,
                        showClear: false
                }
        )
        const emit = defineEmits(['update:modelValue', "change"])
        const theModel = useVModel(props, 'modelValue', emit)

        const change = (event:DropdownChangeEvent) => emit('change', event)
</script>
