<template>
    <div class="frameWindow clientsWindow" v-if="loaded">
            <h3>Контрагенты</h3>
            <Tabs class="objectsTab" value="0" @update:value="updateTab">
                        <TabList>
                                <Tab value="0">Все</Tab>
                                <Tab value="1" @click="hideFilter = false">Фильтр</Tab>
                        </TabList>
                </Tabs>

            <FilterClientPanel v-show="isViewFilter" @hide="() => hideFilter = true" @apply="applyFilter" @clear="clearFilter"></FilterClientPanel>

            <div class="bodyFrame">
                    <div class="w-full flex flex-column h-full">
                            <ListBase :columns="columns" :apiLoader="clientApi" v-model="selectedObject"
                                      class="listBase"
                                      :sortField="{field:'id', direction : 'DESC'}"
                                      :editingRow="false"
                                      :elipsisSupport="true"
                                      :custom_filters="customFilter"
                                      :hard_filters="hardFilter"
                                      ref="list"
                                      @addRow="addRow"
                                      @loaded="onLoadedList">
                                    <template #is_company="{data}">
                                        {{ (data.data.is_company == true) ? "Да" : "Нет" }} 
                                    </template>
                                    <template #buttons="{data}">
                                            <Button icon="pi pi-pencil" class="mr-2" @click="editRow(data.data)"/>
                                            <Button icon="pi pi-trash" class="red_button mr-2"
                                                    @click="removeClient(data.data)"/>
                                    </template>
                            </ListBase>
                    </div>
            </div>
    </div>

    <EditorClient v-model="visibleEditor" :editingData="editingData" v-if="editingData" @save="saveClient"></EditorClient>
</template>

<script setup lang="ts">
    import controller from "@/window/controller/controller";
    import {CClient, CFilterClient} from "@/interfaces"
    import {clientApi, api} from "@/ts/api"
    import ListBase from '@/components/ListBase.vue'
    import EditorClient from '@/editors/EditorClient.vue'
    import FilterClientPanel from '@/panels/FilterClientPanel.vue'
    
    const columns = [
        {id: "id", header: "ID", style: "width : 75px; flex-shrink:0", sortable: true},
        {id: "name", header: "Название", style: "width : 40%", sortable: true},
        {id: "is_company", header: "Компания (да/нет)", style: "width : 20%", sortable: true},
        {id: "inn", header: "ИНН", style: "width : 30%", sortable: true},
        {id: "buttons", header: "Кнопки", style: "width : 140px; flex-shrink:0"},
    ]

    const {
        loaded, list, selectedObject, customFilter, hardFilter,
        onLoadedList,
        activeTab, updateTab,
        hideFilter, isViewFilter, applyFilter, clearFilter,
        visibleEditor, editingData, addRow, editRow, save, removeRow
    } = controller({
        filterTabIndex: "1",
        supportTab: true,
        supportPrepareEdit: false,
        classFilter: CFilterClient,
        classRow: CClient,
        api: clientApi,
        reloadSaveFields: ["name"],
        removeMessage: "Вы действительно хотите удалить Клиента?"
    })

    const saveClient = async (event) => {
        await save(event)
        await api.loadConstants()
    }

    const removeClient = async (data) => {
        await removeRow(data)
        await api.loadConstants()
    }

</script>
