<template>
        <div class='w-full'>
                <div style='width:300px; ' class='pt-5 m-auto'>
                        <div>
                                <UInputField placeHolder='Email' type="input" class="lineInput" v-model="email"></UInputField>
                        </div>
                        <div style='margin: 6px 0'>
                                <UInputField placeHolder='Пароль' type="input" class="lineInput" v-model="password"></UInputField>
                        </div>

                        <div style='margin-top: 6px'>
                                <Button :loading='loading' type='primary' class='w-full' @click='login'>Войти</Button>
                        </div>
                </div>
        </div>


</template>

<script setup lang='ts'>
        import { ref } from 'vue';
        import { authApi } from '@/ts/api/auth.api';

        const email = ref('')
        const password = ref('')
        const errors = ref('')
        const loading = ref(false)

        const authenticated = () => authApi.isAuthenticated()

        const login = async () => {
                errors.value = ''
                loading.value = true
                await authApi.tryLogin(email.value, password.value)
                loading.value = false
        }
</script>
