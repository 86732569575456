import { CEditing, CEditTemplate, IListNameElement } from '@/interfaces';
import common from '@/ts/common';

export class CContract extends CEditing {
        name : string
        actor_id: number
        client_id: number
        active_from : string
        active_to : string
        status : number
        comment : string

        constructor(data: CContract | null = null) {
                super(data);

                if (data == null) {
                        this.name = '';
                        this.actor_id = 0;
                        this.client_id = 0;
                        this.active_from = common.dateFormatView(new Date());
                        this.active_to = common.dateFormatView(new Date());
                        this.status = 1;
                        this.comment = '';
                } else {
                        this.name = data.name;
                        this.actor_id = data.actor_id;
                        this.client_id = data.client_id;
                        this.active_from = data.active_from;
                        this.active_to = data.active_to;
                        this.status = data.status;
                        this.comment = data.comment;
                }
        }

        fillForSave(dataTo: CContract) {
                dataTo.name = this.name.trim();
                dataTo.actor_id = this.actor_id;
                dataTo.client_id = this.client_id;
                dataTo.active_from = this.active_from;
                dataTo.active_to = this.active_to;
                dataTo.status = this.status;
                dataTo.comment = this.comment.trim();
        }

        isDisableSave(): boolean {
                return this.name.trim() === '' ||
                        this.active_from >= this.active_to ||
                        !(this.status|this.actor_id|this.client_id)
        }
}


export  class CAdditionDataContract extends CEditTemplate {
        actors : IListNameElement[]
        clients : IListNameElement[]

        constructor(data: CAdditionDataContract) {
                super(data);
                this.actors = data.actors
                this.clients = data.clients
        }
}
