import CApiBase from '@/ts/api/apiBase';
import { BillingStore } from '@/ts/store';
import { AxiosError } from 'axios';
import common from '@/ts/common';
import { router } from '@/ts/router';

export class CAuthApi extends CApiBase {
        isAuthenticated() {
                return !!this.get_token();
        }

        async tryLogin(email, password) {
                let cStore = BillingStore();
                try {
                        let { data } = await this.post(this.getRoot("/auth/login"), {email, password})
                        cStore.accessToken = data.accessToken
                        this.set_token(data.accessToken, data.expiresIn + Date.now() )
                        window.location.href = cStore.currentUrl
                } catch(er:AxiosError) {
                        cStore.accessToken = ""
                        common.viewError(er.response.data.message)
                }
        }

        set_token(token, expiration) {
                localStorage.setItem('token', token);
                localStorage.setItem('expiration', expiration);
        }

        get_token() {
                let token = localStorage.getItem('token');
                let expiration = localStorage.getItem('expiration');

                if (!token || !expiration) return null;

                if (expiration < Date.now()) {
                        this.destroyToken();
                        return null;
                }

                return token;
        }

        async logout() {
                await this.post(this.getRoot("/auth/logout"), {})
                this.destroyToken();
        }

        destroyToken() {
                localStorage.removeItem('token');
                localStorage.removeItem('expiration');
        }
}

export const authApi = new CAuthApi()
