export interface IColumnData {
    id : string
    header : string
    sortable : boolean
    style : string,
    templated : boolean
}

export const getTColumnData = () => {
    return [{id: "id", header: "header", sortable: true, style: "", templated: false}]
}