export class CFilter {
        constructor(flt : CFilter|null = null) {
                /**/
        }
}

export class CFilterIdName extends CFilter {
        id:string
        name:string

        constructor(flt : CFilterIdName|null = null) {
                super(flt)

                if(flt === null) {
                        this.id = ""
                        this.name = ""
                } else {
                        this.id = flt.id
                        this.name = flt.name
                }
        }

}