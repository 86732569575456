import {CEditing} from "@/interfaces";

export class CProduct extends CEditing {
        name = ""
        comment = ""

        constructor(data: CProduct | null = null) {
                super(data)

                if(data == null) {
                        this.name = ""
                        this.comment = ""
                } else {
                        this.name = data.name
                        this.comment = data.comment
                }
        }

        fillForSave(dataTo : CProduct) {
            dataTo.name = this.name.trim();
            dataTo.comment = this.comment.trim();
        }

        isDisableSave() : boolean { 
            return !!(this.name.trim() == '')
        }

}
