import {CFilterIdName} from "@/interfaces";
import common from '@/ts/common';

export class CFilterProgclient extends CFilterIdName {
    product_id: number|null

    constructor(flt : CFilterProgclient|null = null) {
        super(flt)

        if(flt === null) {
            this.product_id = null
        } else {
            this.product_id = flt.product_id
        }
    }
}
