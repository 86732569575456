import {CEditing} from "@/interfaces";

export class CProgclient extends CEditing {
        name: string
        product_id: number
        secret: string

        constructor(data: CProgclient | null = null) {
                super(data)

                if(data == null) {
                        this.name = ""
                        this.product_id = 0
                        this.secret = ""
                } else {
                        this.name = data.name
                        this.product_id = data.product_id
                        this.secret = data.secret
                }
        }

        fillForSave(dataTo : CProgclient) {
            dataTo.name = this.name.trim();
            dataTo.product_id = this.product_id;
            dataTo.secret = this.secret.trim();
        }

        isDisableSave() : boolean { 
            return !!((this.name.trim() == '') || (this.product_id === 0) || (this.secret.trim() == ''))
        }

}
