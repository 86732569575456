import {CApi} from "./api";
import common from '@/ts/common';

export class CLicenseApi extends CApi {
    save(data : object, path : string) {
        let param = JSON.parse(JSON.stringify(data))
        param.active_from = common.dateFormatSave(data.active_from)
        param.active_to = common.dateFormatSave(data.active_to)
        param.access_expiration_at = common.dateFormatSave(data.access_expiration_at)
        return this.post(this.getRoot("save"), {data : param, path})
}
}

export const licenseApi = new CLicenseApi("license")
