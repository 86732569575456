import {CApi} from "./api";
import common from '@/ts/common';

export class CContractApi extends CApi {
        save(data : object, path : string) {
                let param = JSON.parse(JSON.stringify(data))
                param.active_from = common.dateFormatSave(data.active_from)
                param.active_to = common.dateFormatSave(data.active_to)
                return this.post(this.getRoot("save"), {data : param, path})
        }

}

export const contractApi = new CContractApi("contract")
