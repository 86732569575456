<template>
        <Drawer v-model:visible='theModel' position='right' class='slidePanel editPanelActor' :dismissable='false'>
                <template #header>
                        <div>{{ header }}</div>
                </template>

                <div class='editingbody'>
                        <UInputField type='input' v-model='contract.name' name='Наименование контракта' :asterics=true :limit=100></UInputField>
                        <UDropdownBox name="Актор" :options="actors" v-model="contract.actor_id" class="mt-3" :asterics="true"></UDropdownBox>
                        <UDropdownBox name="Контрагент" :options="clients" v-model="contract.client_id" class="mt-3" :asterics="true"></UDropdownBox>
                        <span class="mt-3 mr-2">Дата начала</span> <DatePicker v-model="active_from" class="mt-3 mr-4" dateFormat="dd.mm.yy"/>
                        <span class="mt-3 mr-2">Дата окончания</span> <DatePicker v-model="active_to" class="mt-3" dateFormat="dd.mm.yy"/>
                        <UDropdownBox name="Статус" :options="statuses" v-model="contract.status" class="mt-3" :asterics="true" ></UDropdownBox>
                        <UInputField v-model='contract.comment' name='Комментарий' class='mt-3'></UInputField>
                </div>

                <div class='downButtonPanel'>
                        <Button @click='save' v-if='!isCreate' :disabled='disableSave'>Сохранить</Button>
                        <Button @click='save' v-if='isCreate' :disabled='disableSave'>Создать</Button>
                </div>
        </Drawer>
</template>

<script setup lang='ts'>
    import { computed, ref, watch } from 'vue';
    import { CAdditionDataContract, CContract } from '@/interfaces';
    import controllerEditor from '@/editors/controller/controllerEditor';
    import { BillingStore } from '@/ts/store';
    import common from '@/ts/common';

    const props = defineProps<{ modelValue: boolean, editingData: CContract }>();
    const emit = defineEmits(['update:modelValue', 'save']);
    let cStore = BillingStore();

    const actors = computed(() => cStore.actors)
    const clients = computed(() => cStore.clients)
    const statuses = computed(() => cStore.statuses)

    const contract = computed(() => props.editingData);

    const active_from = ref(common.dateDate(contract.value.active_from))
    const active_to = ref(common.dateDate(contract.value.active_to))

    watch(active_from, () => contract.value.active_from = common.dateFormatView(active_from.value))
    watch(active_to, () => contract.value.active_to = common.dateFormatView(active_to.value))

    const {
            theModel,
            header,
            save,
            disableSave,
            isCreate
    } = controllerEditor({
            props, emit,
            headerCreate: 'Создание контракта',
            headerEdit: 'Редактирование контракта ' + contract.value.id
    });

</script>
