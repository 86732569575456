class ButtonInfo {
        name : string
        func : () => void

        constructor(nm:string, fn:() => void) {
                this.name = nm
                this.func = fn
        }
}

export class CDlgData {
        header: string
        visible: boolean
        closable: boolean
        message: string
        icon: string
        class : string
        buttons : ButtonInfo[]
        resolve : ((param?:boolean) => void)|null
        hide : () => void

        constructor() {
                this.header = "Ошибка"
                this.visible = false
                this.closable = true
                this.message = ""
                this.icon = ''
                this.class = ""
                this.buttons = []
                this.resolve = null
                this.hide = () => {/**/}
        }

        clear() {
                this.header = "Ошибка"
                this.visible = false
                this.closable = true
                this.message = ""
                this.hide = () => {/**/}
        }
}

const common = {
        dlgData: new CDlgData(),
        dlgError : new CDlgData(),

        init(dlg: CDlgData, dlgerr: CDlgData) {
                this.dlgData = dlg
                this.dlgError = dlgerr
        },

        shureRemove(msg : string) {
                return this.shure(msg, "<i class='pi pi-exclamation-triangle'></i>", "Удалить")
        },

        shureRestore(msg : string) {
                return this.shure(msg, "<i class='pi pi-exclamation-triangle'></i>", "Восстановить")
        },

        async shure(msg : string, icon?:string, btn?:string) {
                this.dlgData.message = msg;
                this.dlgData.icon = icon||"";
                this.dlgData.header = "Удаление"
                this.dlgData.class = "hint"
                this.dlgData.closable = true
                this.dlgData.visible = true
                this.dlgData.buttons = [
                        new ButtonInfo(btn||"Да", () => {
                                if(this.dlgData.resolve) this.dlgData.resolve(true)
                                this.dlgData.visible = false
                        })
                ]
                this.dlgData.hide = () => {
                        if(this.dlgData.resolve) this.dlgData.resolve(false)

                }
                return new Promise((resolve) => this.dlgData.resolve = resolve)
        },

        viewError(msg : string) {
                this.dlgError.message = msg;
                this.dlgError.visible = true
        },

        viewMessage(msg : string) {
                this.dlgData.header = "Сообщение"
                this.dlgData.message = msg
                this.dlgData.closable = true
                this.dlgData.visible = true
        },

        waitDialog(msg : string) {
                this.dlgData.header = "Внимание!"
                this.dlgData.message = msg
                this.dlgData.closable = false
                this.dlgData.visible = true
        },

        closeWaitDialog() {
                this.dlgData.visible = false
        },

        scrollListToSelect(list) {
                if(!list || !list.$el) return
                let hl = list.$el.getElementsByClassName("p-highlight");
                if(!hl.length) return
                let topSelEl = hl[0].offsetTop - hl[0].parentNode.offsetTop;
                list.$el.getElementsByClassName("p-listbox-list-wrapper")[0].scrollTop = topSelEl;
        },

        scrollDataTableToSelect(list) {
                if(!list) return
                let hl = list.getElementsByClassName("p-selectable-row p-highlight");
                if(!hl.length) return
                let topSelEl = hl[0].offsetTop - hl[0].parentNode.offsetTop;
                hl[0].parentNode.parentNode.parentNode.scrollTop = topSelEl;
//                list.getElementsByClassName("p-listbox-list-wrapper")[0].scrollTop = topSelEl;
        },

        dateDate(dateStr:string) {
                let ares = dateStr.split(".")
                return new Date(`${ares[1]}.${ares[0]}.${ares[2]}`);
        },

        dateFormatSave(dateStr:string|Date) {
                let date
                if (dateStr) {
                    if(typeof dateStr === 'string') {
                        //if(!dateStr) return ""
                        date = this.dateDate(dateStr)
                    } else {
                        date = dateStr
                    }
                } else {
                    return ""
                }
                
                let dd:string|number = date.getDate();
                let mm:string|number = date.getMonth() + 1; //January is 0!
                if (dd < 10) dd = '0' + dd;
                if (mm < 10) mm = '0' + mm;
                return `${date.getFullYear()}-${mm}-${dd}`;
        },

        // Эта функция вызывается из 2 мест
        // 1 - из датапикера для преобразования Date в текстовый вид
        // 2 - из базы данных, где данный в виде строки, но в другом формате
        dateFormatView(dateStr:string|Date) {
                let date = new Date(dateStr);
                let dd:string|number = date.getDate();
                let mm:string|number = date.getMonth() + 1;
                if (dd < 10) dd = '0' + dd;
                if (mm < 10) mm = '0' + mm;
                return `${dd}.${mm}.${date.getFullYear()}`;
        }

        /*statusFormatView(statusIdStr:string) {
            switch (statusIdStr) {
                case 'new':
                    return 'Новый';
                    break;
                case 'active':
                    return 'Действующий';
                    break;
                case 'inactive':
                    return 'Недействующий';
                    break;
                case 'фксрш':
                    return 'Недействующий';
                    break;
            }
        }*/
}

export default common
