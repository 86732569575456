<template>
        <div class='frameWindow progclientsWindow' v-if='loaded'>
                <h3>Программные клиенты</h3>
                <Tabs class='objectsTab' value='0' @update:value='updateTab'>
                        <TabList>
                                <Tab value='0'>Все</Tab>
                                <Tab value='1' @click='hideFilter = false'>Фильтр</Tab>
                        </TabList>
                </Tabs>

                <FilterProgclientPanel v-show='isViewFilter' @hide='() => hideFilter = true' @apply='applyFilter' @clear='clearFilter'></FilterProgclientPanel>

                <div class='bodyFrame'>
                        <div class='w-full flex flex-column h-full'>
                                <ListBase :columns='columns' :apiLoader='progclientApi' v-model='selectedObject'
                                          class='listBase'
                                          :sortField="{field:'id', direction : 'DESC'}"
                                          :editingRow='false'
                                          :elipsisSupport='true'
                                          :custom_filters='customFilter'
                                          :hard_filters='hardFilter'
                                          ref='list'
                                          @addRow='addRow'
                                          @loaded='onLoadedList'>
                                        <template #buttons='{data}'>
                                                <Button icon='pi pi-pencil' class='mr-2' @click='editRow(data.data)' />
                                                <Button icon='pi pi-trash' class='red_button mr-2'
                                                        @click='removeRow(data.data)' />
                                        </template>
                                </ListBase>
                        </div>
                </div>
        </div>

        <EditorProgclient v-model='visibleEditor' :editingData='editingData' v-if='editingData' @save='save'></EditorProgclient>
</template>

<script setup lang='ts'>
        import controller from '@/window/controller/controller';
        import { CProgclient, CFilterProgclient } from '@/interfaces';
        import { progclientApi, api } from '@/ts/api';
        import ListBase from '@/components/ListBase.vue';
        import EditorProgclient from '@/editors/EditorProgclient.vue';
        import FilterProgclientPanel from '@/panels/FilterProgclientPanel.vue';
        import { BillingStore } from '@/ts/store';

        const columns = [
                { id: 'id', header: 'ID', style: 'width : 75px; flex-shrink:0', sortable: true },
                { id: 'name', header: 'Название', style: 'width : 50%', sortable: true },
                { id: 'product', header: 'Программный продукт', style: 'width : 40%', sortable: true },
                { id: 'buttons', header: 'Кнопки', style: 'width : 140px; flex-shrink:0' }
        ]

        let cStore = BillingStore();

        const {
                loaded, list, selectedObject, customFilter, hardFilter,
                onLoadedList,
                activeTab, updateTab,
                hideFilter, isViewFilter, applyFilter, clearFilter,
                visibleEditor, editingData, addRow, editRow, save, removeRow
        } = controller({
                filterTabIndex: '1',
                supportTab: true,
                supportPrepareEdit: false,
                classFilter: CFilterProgclient,
                classRow: CProgclient,
                api: progclientApi,
                reloadSaveFields: ['name'],
                removeMessage: 'Вы действительно хотите удалить Программный клиент?'
        })

</script>
