import {defineStore} from 'pinia';
import {CUserProfile} from '../interfaces';
import { IListNameElement } from '@/interfaces';

export type TBillingStore = {
        currentUrl : string,
        accessToken : string|null,
        profile : CUserProfile,
        statuses : IListNameElement[],
        statuses_license : IListNameElement[],
        period_license : IListNameElement[],
        actors : IListNameElement[],
        clients : IListNameElement[],
        products : IListNameElement[]
}

export const BillingStore = defineStore({
        id: 'main',

        state: () => ({
                currentUrl : "/",
                accessToken : null,
                profile : new CUserProfile,
                statuses : [],
                statuses_license : [],
                period_license : [],
                actors : [],
                clients : [],
                products : []
        } as unknown as TBillingStore),

        getters: {
                getAccessToken: state => state.accessToken,
                getUserProfile: state => state.profile,
                getActors: state => state.actors,
                getClients: state => state.clients,
                getStatuses: state => state.statuses,
                getStatuses_licenses: state => state.statuses_license,
                getPeriod_license: state => state.period_license,
                getProducts: state => state.products
        },

        actions : {}
})
