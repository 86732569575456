import {CEditing} from "@/interfaces";

export class CActor extends CEditing {
        name = ""
        inn = ""
        comment = ""

        constructor(data: CActor | null = null) {
                super(data)

                if(data == null) {
                        this.name = ""
                        this.inn = ""
                        this.comment = ""
                } else {
                        this.name = data.name
                        this.inn = data.inn
                        this.comment = data.comment
                }
        }

        fillForSave(dataTo : CActor) {
            dataTo.name = this.name.trim();
            dataTo.inn = this.inn.trim();
            dataTo.comment = this.comment.trim();
        }

        isDisableSave() : boolean { 
            return !!((this.name.trim() == '') || (this.inn.trim() == ''))
        }

}
