import {onMounted, ref, watch} from 'vue'
import {sectionApi} from "@/ts/api";
import {CFilter} from "@/interfaces/CFilter";

export interface IControllerFilterInit {
        emit : any,
        props ?: any,

        classFilter : typeof CFilter
}


export default function controllerFilter(init:IControllerFilterInit) {
        // Фильтр внутри панели
        const curFilter = ref(new init.classFilter())

        const hide = () => init.emit("hide")
        const applyFilter = () => init.emit("apply", curFilter.value)
        const clearFilter = () => {
                curFilter.value = new init.classFilter()
                init.emit("clear")
        }

        return {
                curFilter,
                hide, applyFilter, clearFilter
        }
}
