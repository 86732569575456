import axios from 'axios';
import {BillingStore} from "../store";

export default class CApiBase {
        basePath: string;
        startUrl: string;

        constructor(url = '') {
                this.basePath = process.env.PUBLIC_TOOLSERVER || ''
                this.startUrl = url
        }

        getRoot(url: string) {
                return this.basePath + this.startUrl + url;
        }

        post(url: string, param: any) {
                let cStore = BillingStore();
                const options:any = { headers : { Authorization : 'Bearer ' + cStore.getAccessToken }}
                return axios.post(url, param, options)
        }

        async login(aT:string|null) {
/*                if(aT == null || aT == '{{token}}') {
                        const {data} = await axios.post(this.basePath + '/auth/login', {email: process.env.PUBLIC_LOGIN_EMAIL, ps : process.env.PUBLIC_LOGIN_PS})
                        cStore.accessToken = data.accessToken
                } else*/
                let cStore = BillingStore();
                cStore.accessToken = aT
                const {data} = await this.post(this.basePath + '/auth/getProfile', {})
                cStore.profile = data
//                cStore.profile.name = ret.data.name
  //              cStore.profile.access = ret.data.access
        }

        async loadConstants() {
                const ret = await this.post(this.basePath + '/loadConstants', {})
                let cStore = BillingStore();
                cStore.actors = ret.data.actors
                cStore.clients = ret.data.clients
                cStore.statuses = ret.data.statuses
                cStore.statuses_license = ret.data.statuses_license
                cStore.period_license = ret.data.period_license
                cStore.products = ret.data.products
        }
}
