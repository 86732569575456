import { CEditing, CEditTemplate, IListNameElement } from '@/interfaces';
import common from '@/ts/common';

export class CLicense extends CEditing {
    name : string
    contract_id: number
    priority: boolean
    status : number
    active_from : string
    active_to : string
    access_quantity: number
    access_granted: number
    access_expiration_at: string
    access_period: number
    scope_json: string
    product_id: number
    comment: string

    constructor(data: CLicense | null = null) {
        super(data);

        if (data == null) {
            this.name = '';
            this.contract_id = 0;
            this.priority = false;
            this.status = 0;
            this.active_from = common.dateFormatView(new Date());
            this.active_to = common.dateFormatView(new Date());
            this.access_quantity = 0;
            this.access_granted = 0;
            this.access_expiration_at = common.dateFormatView(new Date());
            this.access_period = 0;
            this.scope_json = '';
            this.product_id = 0;
            this.comment = '';
        } else {
            this.name = data.name;
            this.contract_id = data.contract_id;
            this.priority = data.priority;
            this.status = data.status;
            this.active_from = data.active_from;
            this.active_to = data.active_to;
            this.access_quantity = data.access_quantity;
            this.access_granted = data.access_granted;
            this.access_expiration_at = data.access_expiration_at;
            this.access_period = data.access_period;
            this.scope_json = data.scope_json;
            this.product_id = data.product_id;
            this.comment = data.comment;
        }
    }

    fillForSave(dataTo: CLicense) {
        dataTo.name = this.name.trim();
        dataTo.contract_id = this.contract_id;
        dataTo.priority = this.priority;
        dataTo.status = this.status;
        dataTo.active_from = this.active_from;
        dataTo.active_to = this.active_to;
        dataTo.access_quantity = this.access_quantity;
        dataTo.access_granted = this.access_granted;
        dataTo.access_expiration_at = this.access_expiration_at;
        dataTo.access_period = this.access_period;
        dataTo.scope_json = this.scope_json;
        dataTo.product_id = this.product_id;
        dataTo.comment = this.comment.trim();
    }

    isDisableSave(): boolean {
        return this.name.trim() === ""
            /*|| (this.active_from >= this.active_to) || (this.status === '') ||
            this.actor_id === 0 || this.client_id === 0*/
    }
}

export  class CAdditionDataLicense extends CEditTemplate {
        contracts : IListNameElement[]

        constructor(data: CAdditionDataLicense) {
                super(data);
                this.contracts = data.contracts
        }
}
