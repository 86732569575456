import {computed} from "vue";
import {IControllerEditorInit} from "@/interfaces";
import {useVModel} from "@vueuse/core";

export default function controllerEditor(init:IControllerEditorInit) {
        const editingData = computed(() => init.props.editingData)
        const theModel = useVModel(init.props, 'modelValue', init.emit)

        const isSave = computed(() => editingData.value.id !== null)
        const isCreate = computed(() => editingData.value.id === null)
        const disableSave = computed(() => editingData.value.isDisableSave())
        const header = computed(() => (isCreate.value ? init.headerCreate || "Создание" : init.headerEdit || "Редактирование"))

        const save = () => { init.emit("save", editingData.value) }

        return {
                theModel, editingData,
                isSave, isCreate, disableSave, header,
                save
        }
}


