import {CEditing} from "@/interfaces";

export class CClient extends CEditing {
        name = ""
        inn = ""
        is_company = false;
        comment = ""

        constructor(data: CClient | null = null) {
                super(data)

                if(data == null) {
                        this.name = ""
                        this.inn = ""
                        this.is_company = false
                        this.comment = ""
                } else {
                        this.name = data.name
                        this.inn = data.inn
                        this.is_company = data.is_company
                        this.comment = data.comment
                }
        }

        fillForSave(dataTo : CClient) {
            dataTo.name = this.name.trim();
            dataTo.inn = this.inn.trim();
            dataTo.is_company = this.is_company;
            dataTo.comment = this.comment.trim();
        }

        isDisableSave() : boolean { 
            return !!((this.name.trim() == '') || (this.inn.trim() == ''))
        }
        //
}
