<template>
        <div class="UInputField" :class="type">
                <div class='labelline'><label v-if="name">{{ name }}</label><span class="asterics" v-if="asterics">* </span><span class="counter" v-if="counter">({{countLetter}})</span></div>
                <Textarea v-model="theModel" :rows="rows" :autoResize="false" v-if="type === 'textarea'" ref="txtarea" :placeholder="placeHolder" :maxlength="limit" :autofocus="autofocus" ></Textarea>
                <InputText v-model="theModel" v-if="type === 'input'" ref="inputarea" :style="styleInput" :placeholder="placeHolder" :maxlength="limit" :autofocus="autofocus" :disabled="disabled"></InputText>
                <InputNumber v-model="theModel" v-if="type === 'number'" ref="inputarea" :style="styleInput" :maxlength="maxlength" :min="min" :max="max"
                             @input="filterNumber" :autofocus="autofocus" :minFractionDigits="0" :maxFractionDigits="maxFractionDigits"></InputNumber>
        </div>
</template>

<script setup lang="ts">
        import {computed, ref} from 'vue'
        import Textarea from 'primevue/textarea'
        import InputText from 'primevue/inputtext'
        import InputNumber, {InputNumberInputEvent} from 'primevue/inputnumber'
        import {useVModel} from '@vueuse/core'

        const props = withDefaults(
                defineProps<{
                        name?: string | null
                        modelValue?: string | number
                        rows?: number
                        type?: string
                        counter?: boolean
                        limit ?: null|number
                        asterics?: boolean
                        placeHolder?: string,
                        // eslint-disable-next-line
                        styleInput?: any,
                        autofocus ?: boolean
                        disabled ?: boolean,
                        maxlength ?:number,
                        min?:number,
                        max?:number,
                        minFractionDigits?:number,
                        maxFractionDigits?:number|null
                }>(),
                {
                        name: null,
                        modelValue: '',
                        rows: 3,
                        type: 'textarea',
                        counter : false,
                        limit : null,
                        asterics : false,
                        placeHolder : "",
                        styleInput: {},
                        autofocus : false,
                        disabled : false,
                        maxlength : 3,
                        min:0,
                        max:999,
                        minFractionDigits:0,
                        maxFractionDigits:null
                }
        )
        const emit = defineEmits(['update:modelValue'])
        const theModel = useVModel(props, 'modelValue', emit)

        const countLetter = computed(() => {
                let cnt : string = theModel.value + ""
                if(props.limit !== null) return `${cnt.length} /  ${props.limit}`
                return cnt.length
        })

/*        watch(() => theModel.value, () => {
                if(!props.limit) return
                if((theModel.value + "").length > props.limit)
                        theModel.value = (theModel.value + "").slice(0, props.limit)
        })*/


        const txtarea = ref()
        const inputarea = ref()
        const setFocus = () => txtarea.value.$el.focus()

        const filterNumber = (ev:InputNumberInputEvent) => {
                if(props.max !=null && ev?.value > props.max) theModel.value = props.max
        }

        defineExpose({setFocus})
</script>
