import {definePreset} from '@primevue/themes'
import Aura from '@primevue/themes/aura'

export const MyPreset = definePreset(Aura, {
        semantic: {
                primary: {
                        50: '#e8f4fe',
                        100: '#2196f3',
                        200: '#2196f3',
                        300: '#2196f3',
                        400: '#2196f3',
                        500: '#2196f3',
                        600: '#0d89ec',
                        700: '#2196f3',
                        800: '#2196f3',
                        900: '#2196f3',
                        950: '#2196f3'
                },
                border: {
                        radiusMd: '3px'
                },
                list: {
                        "option-selected-focus-background": 'transparent !important'
                }
        },
        components: {
                button: {
                        paddingX: '1rem',
                        borderRadius: '3px'
                },
                checkbox: {
                        hoverBorderColor: '#2196f3'
                },
                tabmenu: {
                        itemBorderWidth: '0 0 2px 0',
                        itemHoverColor: '#6c757d'
                },
                toggleswitch: {
                        background: '#ced4da',
                        width: '3rem',
                        height: '1.75rem',
                        handleSize: '1.25rem',
                        hoverBackground: '#b6bfc8'
                },
                textarea: {
                        color: '#495057',
                        paddingY: '10px',
                        paddingX: '16px'
                },
                editor: {
                        toolbarBorderColor: 0
                },
                datatable: {
                        headerCellPadding: '1rem',
                        rowHoverBackground: '#e8f4fe',
                        rowColor: '#495057',
                        bodyCellBorderColor: '#e9ecef',
                },
                tabs: {
                        tabMargin: '0 0 -2px 0',
                        tabpanelPadding: 0
                },
                drawer: {
                        headerPadding: '0.8rem'
                }
        }
})
