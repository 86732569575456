<template>
        <Drawer v-model:visible="theModel" position="right" class="slidePanel editPanelProgclient" :dismissable="false">
                <template #header>
                        <div>{{ header }}</div>
                </template>

                <div class="editingbody">
                    <UInputField type="input" v-model="progclient.name" name="Название программного клиента" :asterics=true :limit=100></UInputField>
                    <UDropdownBox name="Программный продукт" :options="products" v-model="progclient.product_id" class="mt-3" :asterics="true"></UDropdownBox>
                    <UInputField type="input" v-model="progclient.secret" name="Секретный код" :asterics=true :limit=100></UInputField>
                </div>

                <div class="downButtonPanel">
                        <Button @click="save" v-if="!isCreate" :disabled="disableSave">Сохранить</Button>
                        <Button @click="save" v-if="isCreate" :disabled="disableSave">Создать</Button>
                </div>
        </Drawer>
</template>

<script setup lang="ts">
        import {computed} from "vue";
        import {CProgclient} from "@/interfaces";
        import controllerEditor from "@/editors/controller/controllerEditor";
        import { BillingStore } from '@/ts/store';


        const props = defineProps<{ modelValue: boolean, editingData: CProgclient }>()
        const emit = defineEmits(['update:modelValue', "save"])
        let cStore = BillingStore();

        const products = computed(() => cStore.products)

        const progclient = computed(() => props.editingData)

        const {
                theModel,
                header,
                save,
                editingData,
                disableSave,
                isCreate
        } = controllerEditor({
                props, emit,
                headerCreate: "Создание программного продукта",
                headerEdit: "Редактирование программного продукта " + progclient.value.id,
        })

</script>
