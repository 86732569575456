export class CUserProfile {
        id : number
        name : string
        access : string[]

        constructor() {
                this.id = 0
                this.name = ""
                this.access = []
        }
}
