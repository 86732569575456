import {CFilterIdName} from "@/interfaces";

export class CFilterClient extends CFilterIdName {
        inn:string

        constructor(flt : CFilterClient|null = null) {
                super(flt)

                if(flt === null) {
                        this.inn = ""
                } else {
                        this.inn = flt.inn
                }
        }

}